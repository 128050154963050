/*
 * небольшие кастомные переопределения цветов,
 * не в sass, т.к. не нужны префиксы классов.
 * Классы для пикера даты и времени:
 */
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #f9612b;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover {
    background-color: #fa794b;
}

/**
 * Классы для пикера времени
 */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #f9612b;
}

/**
 * Паддинги для выравнивая стрелочек смены месяцев
 */
.react-datepicker__navigation.react-datepicker__navigation--previous, .react-datepicker__navigation.react-datepicker__navigation--next {
    padding-top: 8px;
}
