@import "bootstrap/bootstrap";
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/utilities";

body {
  font-family: 'Nunito', sans-serif;
  overflow-y: scroll; // чтобы показ/скрытие скроллбара не сдвигало контент, скроллбар показывается всегда
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h3 {
  font-size: 26px;
  line-height: 32px;
}

h4 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 600;
}

h6 {
  font-size: 17px;
  line-height: 20px;
  font-weight: 600;
}

.text-smaller {
  font-size: .875rem;
  line-height: 1.1875rem;
}

.link-body {
  &:hover {
    color: tint-color($body-color, $btn-hover-bg-tint-amount);
  }
}

.orange {
  color: $orange;
}

.gray-500 {
  color: $gray-500;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.white {
  color: $white;
}

.bg-white {
  background-color: #ffffff;
}

.clickable {
  cursor: pointer;
}

.pre-wrap {
  white-space: pre-wrap;
}

//$additional-margins: (
//        "0_5": 0.25rem,
//        //"2_5": 0.75rem,
//        "3_5": 1.25rem,
//);
//
//@each $name, $value in $additional-margins {
//  .mt-#{$name} {
//    margin-top: $value;
//  }
//
//  .me-#{$name} {
//    margin-right: $value;
//  }
//
//  .mb-#{$name} {
//    margin-bottom: $value;
//  }
//
//  .ms-#{$name} {
//    margin-left: $value;
//  }
//
//  .mx-#{$name} {
//    margin-left: $value;
//    margin-right: $value;
//  }
//
//  .my-#{$name} {
//    margin-top: $value;
//    margin-bottom: $value;
//  }
//
//  .m-#{$name} {
//    margin: $value;
//  }
//
//  .ps-#{name} {
//    padding-left: $value;
//  }
//
//  .pe-#{name} {
//    padding-right: $value;
//  }
//
//  .p-#{name} {
//    padding: $value;
//  }
//}

.fw-600 {
  font-weight: 600;
}

.has-caret {
  &::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    position: relative;
    top: 2px;
  }
}

$utilities: map-merge(
  $utilities, (
    "width": map-merge(
      map-get($utilities, "width"),
        ( responsive: true ),
    ),
  )
);

@media (min-width: map-get($grid-breakpoints, "sm") - 1px) {
  .w-sm {
    &-25 {
      width: 25% !important;
    }

    &-50 {
      width: 50% !important;
    }

    &-75 {
      width: 75% !important;
    }

    &-100 {
      width: 100% !important;
    }

    &-auto {
      width: auto !important;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, "md") - 1px) {
  .w-md {
    &-25 {
      width: 25% !important;
    }

    &-50 {
      width: 50% !important;
    }

    &-75 {
      width: 75% !important;
    }

    &-100 {
      width: 100% !important;
    }

    &-auto {
      width: auto !important;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, "lg") - 1px) {
  .w-lg {
    &-25 {
      width: 25% !important;
    }

    &-50 {
      width: 50% !important;
    }

    &-75 {
      width: 75% !important;
    }

    &-100 {
      width: 100% !important;
    }

    &-auto {
      width: auto !important;
    }
  }
}

.modal-backdrop + .modal + .modal-backdrop {
  z-index: 1060 !important;
}

.modal-backdrop + .modal + .modal-backdrop + .modal {
  z-index: 1061 !important;
}
