.picture {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
