@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v7/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1n-q_4MrImHCIJIZrDCvHOej.woff2) format('woff2');
}

.icon {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  max-width: 24px;
  max-height: 24px;

  // иконка малого размера
  &--small {
    font-size: 16px;
    max-width: 16px;
    max-height: 16px;
  }

  // иконка большого размера
  &--big {
    font-size: 36px;
    max-width: 36px;
    max-height: 36px;
  }
}
